/*
Theme Name: Metik - Construction HTML5 Template.
Author: Slidesigma
Version: 1.0.0
*/
@media (max-width: 1200px) {
	/*navigation*/
	.main-menu>li.menu-item>a{
		padding: 18px 5px;
	}
	.menu-style.style-2 .main-menu>li.menu-item>a{
		padding: 18px 20px;
	}
	.custom-container{
		padding-right: 60px;
    	padding-left: 60px;
	}
	.full-services-sec .right-side{
		padding: 60px;
	}
	/*listing details*/
	.property-basic-info .property-basic-info-wrapper .property-price{
		font-size: 22px;
	}
	.property-basic-info .property-basic-info-wrapper .property-details .property-icon{
		width: 30px;
	}
	.property-basic-info .property-basic-info-wrapper .property-details .property-content{
		width: calc(100% - 0px);
	}
	.property-basic-info .property-basic-info-wrapper .property-details .property-content .property-value{
		font-size: 12px;
	}
	.our-services .filter-gallery>ul>li>a{
		padding: 8px 20px
	}
	.our-services .filter-gallery>ul>li>a:before{
		width: 5%;
	}
	.mb-lg-20 {
		margin-bottom: 20px;
	}
	.mb-lg-30 {
		margin-bottom: 30px;
	}
	.mb-lg-40 {
		margin-bottom: 40px;
	}
	.mb-lg-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 992px) {
	/*general*/
	.clients-box .section-header-right,
	.section-header-right,
	.testimonials-box .section-header{
		margin: 0 auto 0 0;
		padding-left: 15px;
		padding-right: 15px;
		text-align: left;
	}
	.section-header-right{
		padding-left: 0;
		padding-right: 0;
	}
	.section-header-right .section-heading:after,
	.faqs-box .section-header .section-heading:after,
	.testimonials-box .section-header .section-heading:after{
		margin: 0 auto 0 0;
	}
	.section-header-right .section-heading:before {
		right: auto;
		left: 0;
		animation: move 9s ease infinite;
	}
	/*topbar*/
	.topbar.style-2 .right-side ul{
		margin-top: 10px;
	}
	.topbar .left-side ul,
	.topbar.style-2 .right-side ul {
	    justify-content: center;
	}
	.topbar .left-side li{
		margin-right: 10px;
	}
	/*navigation*/
	.menu-style.style-2 .header>.logo{
		display: block;
	}
	.menu-style.style-2 .header{
		justify-content: space-between;
	}
	.menu-style.style-2 .header .right-side .navigation li .logo{
		display: none;
	}
	.main-menu>li.menu-item,
	.menu-item-has-children>.sub-menu>li.menu-item{
		padding: 0;
	}
	.menu-style .header .right-side .navigation .logo,
	.menu-style .header .right-side .navigation .social-media,
	.main-menu{
		display: block;
	}
	.menu-style .header .right-side .navigation{
		position: fixed;
	    left: -320px;
	    background: #ffff;
	    width: 320px;
	    top: 0;
	    height: 100vh;
	    transition: 0.5s all;
	    overflow: auto;
	        z-index: 100;
	}
	.menu-style .header .right-side .navigation .logo{
		margin: 10px auto;
	}
	.menu-style .header .right-side .navigation.active .social-media,
	.menu-style .header .right-side .navigation.active{
		left: 0;
	}
	.menu-style .header .right-side .hamburger-menu{
		display: flex;
		margin-right: 20px;
	}
	.menu-style .header .right-side .navigation .main-menu>li.menu-item>a{
		display: flex;
		justify-content: space-between;
		color: #000000;
	}
	.main-menu>li.menu-item>a:after{
		bottom: 0;
	}
	.menu-style .header .right-side-2,
	.main-menu>li.menu-item:hover>a:after, .main-menu>li.menu-item.active>a:after{
		display: none;
	}
	.menu-item-has-megamenu:hover>a:after, .menu-item-has-children:hover>a:after {
	    transform: rotate(0deg);
	}
	.menu-item-has-megamenu.open a>arrow:after,
	.menu-item-has-children.open a>arrow:after{
		transform: rotate(180deg);
	}
	.menu-item-has-children.nav-postion:hover>.sub-menu {
	    top: 0;
	    transform: translateY(0%);
	}
	.menu-item-has-megamenu>.megamenu,
	.menu-item-has-children>.sub-menu{
		width: 100%;
		position: relative;
		opacity: 1;
		transform: translateY(0px);
	    visibility: visible;
	    display: none;
	    border-bottom: none;
	}
	.menu-item-has-megamenu.open .megamenu,
	.menu-item-has-children.open .sub-menu{
		border-bottom: solid 2px #ed673c;
	}
	.main-menu li.menu-item-has-children.open>a{
	    color: #ed673c;
	}
	.menu-item-has-children .sub-menu li.menu-item-has-children>.sub-menu {
	    left: 0;
	    top: 0;
	    margin-bottom: 10px;
	}
	/*banner*/
	.banner-style-2{
		height: 1000px;
	}
	.property-basic-info .property-basic-info-wrapper{
		flex-wrap: wrap;
		height: auto;
	}
	.property-basic-info .property-basic-info-wrapper .property-cta,
	.property-basic-info .property-basic-info-wrapper .property-price{
		width: 100%;
	}
	.property-basic-info .property-basic-info-wrapper .property-details{
		width: 50%;
		border-left: 0;
		padding-left: 0;
	}
	/*Categories tab*/
	.categories-tabs .nav .nav-item {
	    width: 50%;
	}
	.contact-us .contact-us-form{
		margin-left: 0;
		margin-bottom: 0;
	}
	.contact-us .contact-info{
		margin-bottom: 80px;
	}
	.client-tesimonial .testimonials-box.arrow-shape-2:before,
	.arrow-shape-1:after,
	.arrow-shape-2:after{
		display: none;
	}
	/*footer*/
	.copyright .payment-logo{
		justify-content: center;
	}
	.copyright p{
		text-align: center;
	}
	.clients-box .clients-wrapper .client-box .client-item{
		height: auto;
	}
	.mb-md-20 {
		margin-bottom: 20px;
	}
	.mb-md-30 {
		margin-bottom: 30px;
	}
	.mb-md-40 {
		margin-bottom: 40px;
	}
	.mb-md-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 767px) {
	/*general*/
	.slider-btn{
		display: none;
	}
	.swiper-pagination{
		display: block;
	}
	.menu-style .header .right-side .hamburger-menu{
		margin-right: 0;
	}
	.custom-tabs>.nav:before,
	.menu-style .header .right-side .cta-btn{
		display: none;
	}
	/*topbar*/
	.custom-tabs>.nav,
	.topbar .left-side ul,
	.topbar .right-side ul{
		justify-content: center;
	}
	.topbar .left-side li,
	.topbar .right-side li{
		margin: 0 10px;
	}
	.menu-style.fixed-header{
		top: 72px;
	}
	/*blog details*/
	.blog-details .social-media-box>ul,
	.blog-details .post-details-tags-social .tags-box{
		justify-content: center;
	}
	/*listing list style*/
	.property-list-view .property-list-wrapper{
		display: block;
	}
	.property-list-view .property-list-wrapper.full-width-layout .property-list-img,
	.property-list-view .property-list-wrapper.full-width-layout .property-list-content,
	.property-list-view .property-list-wrapper .property-list-content,
	.property-list-view .property-list-wrapper .property-list-img {
	    flex: 0 0 100%;
	    max-width: 100%;
	}
	.property-list-view .property-list-wrapper .property-list-img{
		height: 350px;
	}
	.custom-tabs>.nav>.nav-item>.nav-link{
		margin: 4px 4px 10px;
	}
	.cost-calcultor-sec .tab-inner .cost-calculator-box{
		display: block;
	}
	.cost-calcultor-sec .tab-inner .comment-form label, .cost-calcultor-sec .tab-inner .cost-calculator-box label{
		flex: 0 0 calc(100% - 0px);
    	max-width: calc(100% - 0px);
    	margin-bottom: 20px;
	}
	.cost-calcultor-sec .tab-inner .cost-calculator-box .right-sec{
		flex: 0 0 calc(100% - 0px);
    	max-width: calc(100% - 0px);
    	padding: 0;
	}
	.cost-calcultor-sec .tab-inner .total-calculation .icon{
		display: none;
	}
	.cost-calcultor-sec .tab-inner .total-calculation .calculate-value{
		width: 100%;
		text-align: left;
		justify-content: flex-start;
	}
	.conpany-history-style .com-his-left-side .com-his-desc .com-his-box.img,
	.conpany-history-style .com-his-right-side {
	    overflow: hidden;
	    z-index: 1;
	    height: auto;
	}
	.mb-sm-20 {
		margin-bottom: 20px;
	}
	.mb-sm-30 {
		margin-bottom: 30px;
	}
	.mb-sm-40 {
		margin-bottom: 40px;
	}
	.mb-sm-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 576px) {
	.simple-banner .slide-item .content-wrapper h1,
	.video-banner .content-wrapper h1{
		font-size: 36px;
	}
	.simple-banner .slide-item .content-wrapper a,
	.video-banner .content-wrapper a{
		margin-bottom: 10px;
	}
	.topbar .left-side li a i{
		font-size: 14px;
	}
	.topbar .left-side li a{
		font-size: 0;
	}
	.banner-style-2{
		height: 1200px;
	}
	.map-search-box{
		width: 250px;
		left: -250px;
	}
	.custom-container {
	    padding-right: 0px;
	    padding-left: 0px;
	}
	.container-fluid.custom-container{
		padding-right: 15px;
	    padding-left: 15px;
	}
	/*navigation*/

	.banner-style-2 .banner-counter{
		display: none;
	}
	.menu-style .header .right-side .navigation .social-media,
	.menu-style .header .right-side .navigation{
		left: -260px;
		width: 260px;
	}
	/*about*/
	.about-left-side .about-list ul{
		columns: 1;
	}
	.about-left-side{
		margin-left: 0;
	}
	.about-right-side img {
	    padding-top: 20px;
    	padding-left: 20px;
	}
	.about-right-side:before{
		right: 20px;
	}
	/*blog details*/
	.blog-details .post .post-wrapper .post-meta .post-author .author-img{
		display: block;
	}
	.blog-details .post-author .author-img{
		display: none;
	}
	.blog-details .post-author .author-caption{
	    flex: 0 0 calc(100% - 0px);
	    max-width: calc(100% - 0px);
	}
	.comment-box .children{
		padding-left: 0;
	}
	blockquote {
	    padding: 10px;
	}
	.comment-box .comments li article .comment-content .comment-meta .comment-meta-reply {
	    display: flex;
	    margin-top: 15px;
	}
	/*coming soon*/
	.coming-soon .content-wrapper .newsletter-form .input-group{
		display: block;
	}
	.coming-soon .content-wrapper .newsletter-form .input-group-append{
		margin-left: 0;
		margin-top: 20px;
	}
	/*Categories tab*/
	.categories-tabs .nav .nav-item .nav-link{
		padding: 20px 10px;
	}
	.categories-tabs .nav .nav-item .nav-link span{
		font-size: 40px;
	}
	/*listing*/
	.listing-top-heading{
		display: block;
		text-align: center;
	}
	/*listing detail*/
	.listing-detail .image-sec-table .images-wrapper .detail-page-slider-for .slide-item{
		height: 300px;
	}
	.listing-detail .description-box .content-box .listing-infor{
		columns: 2;
	}
	.topbar.style-2 .right-side li.cta-btn,
	.listing-detail .property-details-box .price-box .ratings{
		display: none;
	}
	.listing-detail .description-box .content-box .contact-form .form-footer{
		display: block;
	}
	.property-basic-info .property-basic-info-wrapper .property-details{
		width: 100%;
	}
	/*tesimonials*/
	.clients-box .clients-wrapper .client-box{
		width: calc(50% - 10px);
	}
	.schdule-box{
		display: block;
	}
	.schdule-box .right-side{
		justify-content: flex-start;
		margin-top: 20px;
	}
	.testimonials-box .testimonials-wrapper .testimonials-author{
		width: 75px;
		height: 75px;
	}
	/*listing classic*/
	.service-detail-infro,
	.listing-meta .content-box .meta-content .listing-property-details>ul{
		columns: 1;
	}
	.full-services-sec .right-side .text-wrapper h2{
		font-size: 30px;
	}
	.full-services-sec .right-side {
	    padding: 30px;
	}
	.contact-us .contact-info,
	.contact-us .contact-us-form{
		padding: 20px;
	}
	.testimonial-box .testimonials-inner .testimonials-item{
		margin-bottom: 40px;
	}
	.testimonial-box .testimonials-inner .testimonials-item{
		display: block;
	}
	.testimonial-box .testimonials-inner .testimonials-item .testimonials-img{
		max-width: 200px;
    	margin: 0 auto 20px;
	}
	.testimonial-box .testimonials-inner .testimonials-item .testimonials-content{
		margin-left: 0px;
		padding-left: 10px;
	}
	.testimonial-box .testimonials-inner .testimonials-item cite{
		position: relative;
		top: -10px;
	}
	.listing-detail .description-box .content-box .contact-form .form-footer .condition,
	.listing-top-heading .sort-by,
	.mb-xs-20 {
		margin-bottom: 20px;
	}
	.mb-xs-30 {
		margin-bottom: 30px;
	}
	.mb-xs-40 {
		margin-bottom: 40px;
	}
	.mb-xs-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 420px) {
	.clients-wrapper-side .client-box{
		width: 50.0%;
	}
}
@media (max-width:380px){
	.listing-top-heading .sort-by>span,
	.copyright .payment-logo span{
		display: none;
	}
	.listing-detail .description-box .content-box .listing-infor{
		columns: 1;
	}
	/*coming soon*/
	.coming-soon .content-wrapper .counter .counter-box .inner-box{
		width: 60px;
		height: 60px;
	}
	.full-services-sec .right-side {
	    padding: 15px;
	}
}
@media (max-width:360px){
	.full-services-sec .left-side .service-text .text-wrapper {
    padding: 10px;
		max-width: 330px;
		top: 54%;
	}
}
