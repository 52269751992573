button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
html{
    overflow-x: hidden;
    overflow-y: auto;
}
.menu-style .header .right-side .hamburger-menu .menu-btn.active span:last-child,
.menu-style .header .right-side .hamburger-menu .menu-btn.active span:first-child{
    background-color: #ff742e;
}
.menu-style .header .right-side .navigation .social-media{
    display: none;
}
.custom-accordion .card .card-header .btn-link{
    border-radius: 0;
    border: none;
    line-height: 1.8;
}
.testimonial-box .testimonials-inner .testimonials-item .testimonials-img{
    width: 150px;
    height: 150px;
    object-fit: cover;
}
.testimonial-box .testimonials-inner .testimonials-item .testimonials-content{
    width: calc(100% - 150px);
}
.video-btn-wrapper:before{
    z-index: -1;
}
.comment-box .comments li article .comment-avatar img,
.our_articles .post .post-wrapper .blog-footer-meta .post-author .author-img img{
    width: 50px;
    height: 50px;
}
.preloader{
    position: fixed;
    height: 100%;
}
.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper .popular_post_title{
    align-self: center;
}
.video-btn-wrapper>a{
    display: flex;
    align-items: center;
    justify-content: center;
}
.coming-soon .content-wrapper .counter{
    margin-bottom: 75px;
}
ul.sub-menu {
    list-style: none;
    padding: 0;
}
.card .card-header{
    display: flex;
    flex-basis: 100%;
    order: 1;
    border-radius: 0;
}
.card .collapseparent{
    order: 2;
}
.custom-accordion .card .card-header .btn {
    background: #ededed;
    color: #111111;
}
.custom-accordion .card .card-header .btn:before {
    content: '+';
    background: #111111;
    color: #fff;
}
.custom-accordion .card .collapseparent.show + .card-header .btn{
    background-image: linear-gradient(
        147deg
        , #ea7742 0%, #FF2525 74%);
    color: #fff;
}
.custom-accordion .card .collapseparent.show + .card-header .btn::before{
    content: '-';
    background: #fff;
    color: #fd5d14;
}
.pagination{
    margin-top: -30px;
}
.alert.d-block{
    display: block!important;
}