@charset "utf-8";

/*
Theme Name: Metik - Construction HTML5 Template.
Author: Slidesigma
Version: 1.0.0

%%%%%%% Table of CSS %%%%%%%

1.General code
    1.1 Typography
    1.2 Space margins and padding
    1.3 forms
    1.4 Buttons
    1.5 Section-Colors-&-Backgrounds
    1.6 Modal & popups
    1.7 Accordion
2.Homepage
    2.1 Navigation
    2.2 Slider
    2.3 About Us
    2.4 Categories
    2.5 Portfolio
    2.6 History
    2.7 Services
    2.8 Team
    2.9 Blog
    2.10 Testimonials
    2.11 Clients
    2.12 Partners
    2.13 FAQs
    2.14 Clients
    2.15 Footer
    2.16 Copyright
3.Blog
4.Blog Details
5.Portfolio
6.Portfolio Detail
7.Portfolio Detail Classic
8.Profile
9.About Us
10.Faqs
11.404
12.Coming Soon
13.Contact Us
14.Gallery
15.Service Detail
*/

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:100,300,400,600,700&display=swap&subset=devanagari,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=devanagari,latin-ext');

/*-------animation-------*/

@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes fadeHeaderInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes scale {
    50% {
        transform: scale(1.2);
    }
}

@keyframes slideTopDown {
    0% {
        transform: translateY(0)
    }
    100% {
        transform: translateY(-6px)
    }
}

@keyframes marqueeAnimation {
    0% {
        transform: translateZ(0)
    }
    to {
        transform: translate3d(-100%, 0, 0)
    }
}

@keyframes move {
    50% {
        left: 80px;
    }
}

@keyframes move-verticle {
    50% {
        top: 50px;
    }
}

@keyframes move-right {
    50% {
        right: 50px;
    }
}


/*======================
1.General Code
========================*/

html {
    overflow: hidden;
}

html.overflow {
    overflow-x: hidden;
    overflow-y: visible;
}

button:focus,
*:focus {
    outline: none;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    line-height: 1.8;
    font-weight: 400;
    color: #000000;
    background: #ffffff;
    border-color: #dfdfdf;
    transition: transform ease-in .4s;
    overflow-x: hidden;
}

button {
    background: transparent;
    border: none;
    padding: 0;
}

label {
    line-height: normal;
}

.menu-open {
    background-color: #000000ab;
}

.align-item-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.p-relative {
    position: relative;
}

.custom-container {
    padding-right: 100px;
    padding-left: 100px;
}

.before-none:before,
.after-none:after,
.none {
    display: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.overlay-bg {
    background: #ffffff;
    opacity: 0.8;
}

.overlay-2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.transform-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
    z-index: 1;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before {
    font-size: inherit;
    margin: 0;
}

.animate-img {
    position: relative;
    overflow: hidden;
}

.animate-img:hover img {
    transform: scale(1.1);
    transition: 0.5s;
}


/*preloader*/

.loader {
    overflow: hidden;
}

.preloader {
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    overflow: hidden;
}

.preloader img {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: 0 auto;
}


/*section-header*/

.section-header {
    max-width: 540px;
    margin: 0 auto 0 0;
    padding-bottom: 30px;
    position: relative;
    z-index: 10;
}

.section-header .section-heading {
    position: relative;
}

.section-header .section-heading:after {
    content: "";
    height: 5px;
    width: 80px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin: 0 auto 0 0;
}



.section-header .section-heading h5 {
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.section-header .section-heading h3 {
    padding-bottom: 20px;
    font-weight: 700;
}

.section-header .section-description {
    position: relative;
}


/*section heading right*/

.section-header-right {
    max-width: 550px;
    padding-bottom: 30px;
    text-align: right;
}

.section-header-right .section-heading {
    position: relative;
}

.section-header-right .section-heading:after {
    content: "";
    height: 5px;
    width: 80px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin: 0 0 0 auto;
}

.section-header-right .section-heading:before {
    right: 0;
    left: auto;
    animation: move-right 9s ease infinite;
}

.section-header-right .section-heading h5 {
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.section-header-right .section-heading h3 {
    padding-bottom: 20px;
}

.section-header-right .section-description {
    margin-bottom: 20px;
    position: relative;
}


/*.custom-tooltip*/

.custom-tooltip:after,
.custom-tooltip:before {
    content: attr(data-tip);
    color: #fff;
    background-color: #000;
    font-size: 12px;
    line-height: 20px;
    border-radius: 3px;
    padding: 0 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -30px;
}

.custom-tooltip:after {
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 0;
    transform: translateX(-50%) rotate(45deg);
    top: -22px;
    z-index: -1;
}

.custom-tooltip:hover:before,
.custom-tooltip:hover:after {
    opacity: 1;
}


/*property-tag*/

.property-tag-1:after {
    position: absolute;
    content: '';
    display: block;
    left: 100%;
    top: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 13px solid;
    border-left-color: #000000;
    border-right-color: #000000;
}

.property-tag-1 {
    float: left;
}

.property-tag-2 {
    float: right;
}

.property-tag-1,
.property-tag-2 {
    display: inline-block;
    padding: 8px 15px;
    position: relative;
    line-height: 10px;
    font-size: 14px;
    position: relative;
}

.property-tag-2:after {
    position: absolute;
    content: '';
    display: block;
    right: 100%;
    top: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px solid;
    border-left-color: #ed673c;
    border-right-color: #ed673c;
}


/*slider arrow*/

.swiper-button-next:after,
.swiper-button-prev:after {
    display: none;
}

.swiper-button-next span,
.swiper-button-prev span {
    font-size: 22px;
    background: transparent;
    border: 1px solid #000000;
    color: #ed673c;
    padding: 0 10px;
    position: relative;
}

.swiper-button-prev span:after,
.swiper-button-next span:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 10%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    transition: 0.5s all;
}

.swiper-button-prev span:after {
    background: #000000;
    left: auto;
    right: 0;
}

.slider-btn {
    position: absolute;
    top: -70px;
    right: 15px;
}

.slider-btn .swiper-button-prev {
    left: -90px;
}

.swiper-button-next span {
    border-color: #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    color: #ed673c;
}

.swiper-button-prev:hover span:after,
.swiper-button-next:hover span:after {
    width: 100%;
}

.swiper-button-prev:hover span,
.swiper-button-next:hover span {
    color: #fff;
}


/*swiper pagination*/

.swiper-pagination {
    transform: translate(0%, 50%);
    position: absolute;
    left: 0;
    bottom: -5%;
    right: 0;
    display: none;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 12px;
    height: 6px;
    display: inline-block;
    border-radius: 10px;
    background: #ed673c;
    margin-right: 10px;
    transition: 0.6s;
    border: 2px solid #ed673c;
    position: relative;
    top: 5px;
}

.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 24px;
    height: 6px;
    border-radius: 50px;
    opacity: 1;
    background: #ed673c;
}

.swiper-pagination-bullets .swiper-pagination-bullet:focus {
    outline: 0;
}

.swiper-slide .our_articles .post,
.swiper-slide .property-grid-box {
    margin: 2px;
}


/*slick bullets*/

.slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.slick-dots li {
    padding: 0;
    line-height: 0;
}

.slick-dots li button {
    font-size: 0;
    width: 12px;
    height: 6px;
    border-radius: 10px;
    background: #ed673c;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    margin: 0 5px;
    transition: 0.6s all;
    border: 2px solid #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
}

.slick-dots li.slick-active button {
    width: 24px;
    height: 6px;
    border-radius: 50px;
}


/*pagination*/

.page-item:first-child .page-link,
.page-item:last-child .page-link,
.page-item .page-link {
    border-radius: 0;
}

.page-link:focus {
    box-shadow: none;
    z-index: 1;
}

.page-item .page-link {
    padding: 8px 20px;
    font-size: 14px;
    line-height: 1.5;
    border: 2px solid #eee;
    color: #111111;
}

.page-item:hover .page-link,
.page-item.active .page-link {
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    border-color: #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    color: #fff;
}



/*======================
1.1 Typography
========================*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    color: #111111;
}

h1 {
    font-size: 60px;
    line-height: 1.2;
    font-weight: 700;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 30px;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}

a {
    color: #111111;
    text-decoration: none;
    transition: 0.5s;
}

a:focus,
a:hover {
    color: #ed673c;
    text-decoration: none;
    transition: 0.5s;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
}

ul li,
ol li {
    margin: 0px;
    position: relative;
}

ul.custom {
    list-style: none;
    padding: 0;
}

blockquote {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    padding: 30px;
}

blockquote span {
    width: 50px;
    height: 50px;
    border: 2px solid #ed673c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    font-size: 16px;
    margin-right: 15px;
}

blockquote p {
    font-size: 16px;
    font-style: italic;
    margin: 0;
    font-weight: 500;
    word-break: break-word;
    margin-bottom: 0;
}

blockquote h6 {
    color: #000;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
}

iframe {
    border: none;
    width: 100%;
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
    transition: 0.5s;
}

table th,
table td {
    border: 1px solid #dfdfdf;
    padding: 15px;
}

table {
    border: 1px solid #dfdfdf;
    padding: 15px;
    width: 100%;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fw-100 {
    font-weight: 100;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 900;
}


/*======================
1.2 Space margins and padding
========================*/

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.section-padding {
    padding: 80px 0;
}

.section-padding-top {
    padding-top: 80px;
}

.section-padding-bottom {
    padding-bottom: 80px;
}

.padding-10 {
    padding: 10px;
}

.padding-15 {
    padding: 15px;
}

.padding-20 {
    padding: 20px;
}

.mb-xl-20 {
    margin-bottom: 20px;
}

.mb-xl-30 {
    margin-bottom: 30px;
}

.mb-xl-40 {
    margin-bottom: 40px;
}

.pb-xl-20 {
    padding-bottom: 20px;
}


/*==================
1.3. Forms
====================*/

.form-group {
    margin-bottom: 20px;
}

.form-control-custom::placeholder {
    font-size: 14px;
}

.form-control-custom {
    height: 50px;
    padding: 0.375rem 1.2rem;
    border-radius: 0;
    border: 2px solid #000000;
    width: 100%;
    background: none;
}

.form-control-custom:focus {
    border: 2px solid #f0f0ff;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    box-shadow: none;
}

.custom-select {
    background-image: none;
}

.custom-select:focus {
    box-shadow: none;
}

label.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

label.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    line-height: normal;
    font-size: 14px;
}

label.custom-checkbox:last-child {
    margin-bottom: 10px;
}


/* Create a custom checkbox */

label.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #ccc;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 0px;
}


/* On mouse-over, add a grey background color */

label.custom-checkbox:hover input~.checkmark {
    background-color: #fff;
    border: 1px solid #ed673c;
}


/* When the checkbox is checked, add a blue background */

label.custom-checkbox input:checked~.checkmark {
    background-color: #ed673c;
    border: 1px solid #ed673c;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

label.custom-checkbox input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

label.custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


/*==================
1.4. Buttons
====================*/

.btn-height {
    height: 50px;
}

.btn-first {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-shadow: none;
    cursor: pointer;
    border: 2px solid transparent;
    padding: 0 25px;
    line-height: 44px;
    border-radius: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    z-index: 1;
    user-select: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-submit {
    background-color: transparent;
    color: #111111;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    transition: 0.5s all;
}

.btn-submit:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 10%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    transition: 0.5s all;
}

.btn-submit:focus:before,
.btn-submit:hover:before {
    width: 100%;
}

.btn-submit:focus,
.btn-submit:hover {
    color: #fff;
}

.btn-transparent {
    background-color: #fff;
    color: #111111;
    border: 2px solid #fff;
    transition: 0.5s all;
}

.btn-transparent:focus,
.btn-transparent:hover {
    background-color: transparent;
    color: #ffffff;
    transition: 0.5s all;
}

.btn-border {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    transition: 0.5s all;
}

.btn-border:focus,
.btn-border:hover {
    color: #111111;
    background-color: #fff;
}

.small-btn {
    padding: 0px 10px;
    position: relative;
    z-index: 10;
    font-size: 22px;
    border: 0.1px solid #ed673c;
    color: #fff;
}

.small-btn:hover {
    color: #fff;
}

.small-btn:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 10%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    transition: 0.5s all;
}

.small-btn:hover:after {
    width: 100%;
}


/*==================
1.5. Section-Colors-&-Backgrounds
====================*/

.bg-custom-black {
    background-color: #000000;
}

.bg-custom-white {
    background-color: #ffffff;
}

.bg-light-white {
    background-color: #f4f4f4;
}

.bg-theme {
    background-color: #000000;
}

.bg-custom-blue {
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
}


/*Colors*/

.text-custom-black {
    color: #000000;
}

.text-light-white {
    color: #4a4a4a;
}

.text-dark-grey {
    color: #000000;
}

.text-theme {
    color: #111111;
}

.text-custom-blue {
    color: #ed673c;
}

.text-custom-white {
    color: #ffffff;
}

.text-custom-yellow {
    color: #ff9600;
}


/*======================
1.6 Modal & Popups
========================*/


/*modal popup*/

.modal.show .modal-dialog {
    max-width: 400px;
}

.modal-body {
    padding: 0;
}

.login-register .nav-tabs {
    justify-content: center;
    border: none;
}

.login-register .nav-tabs .nav-item {
    width: 50%;
    text-align: center;
    border-right: 2px solid #ccc;
}

.login-register .nav-tabs .nav-item:last-child {
    border-right: 0;
}

.login-register .nav-tabs .nav-link:focus,
.login-register .nav-tabs .nav-link:hover,
.login-register .nav-tabs .nav-link {
    border-radius: 0;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: #111111;
    border-bottom: 2px solid #ccc;
    padding: 10px 0;
}

.login-register .nav-tabs .nav-link.active {
    border-bottom: 2px solid transparent;
    color: #ed673c;
}

.login-register .social-links ul {
    display: flex;
    justify-content: center;
}

.login-register .social-links ul li a {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #ed673c;
    border-radius: 50%;
    margin: 0px 5px;
    border: 1px solid #ed673c;
}

.login-register .social-links ul li a:hover {
    background-color: #ed673c;
    color: #fff;
}

.login-register .decleration {
    max-width: 250px;
    margin: 0 auto;
    text-align: center;
}


/*======================
1.7 Accordion
========================*/

.custom-accordion .card {
    background: #ffffff;
    border-color: #fff;
    border-radius: 0;
}

.custom-accordion .card .card-header {
    padding: 0;
    background: #ededed;
    border-color: #ededed;
    border-radius: 0;
}

.custom-accordion .card .card-header .btn-link {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    color: #fff;
    cursor: pointer;
}

.custom-accordion .card .card-header .btn-link.collapsed {
    background: #ededed;
    border-color: #ededed;
    color: #111111;
}

.custom-accordion .card .card-header .btn-link:before {
    content: '-';
    background: #fff;
    color: #ed673c;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 16px;
    justify-content: center;
}

.custom-accordion .card .card-header .btn-link.collapsed:before {
    content: '+';
    background: #111111;
    color: #fff;
}

.custom-accordion .card .card-body {
    padding: 15px 0;
}


/*======================
2. Homepage
========================*/


/*map-box*/

.main-map-box {
    height: 800px;
    position: relative;
}

.main-map-box #map-main {
    height: 100%;
}


/*map-search-box*/

.map-search-box {
    width: 350px;
    transition: 0.5s all;
    left: -350px;
}

.map-search-box.active {
    left: 0;
}

.map-search-box .float-btn {
    position: absolute;
    top: 70px;
    right: -50px;
    background: #fff;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05);
}

.map-search-box .float-btn .float-btn-inner i {
    font-size: 30px;
    padding: 0 10px;
}

.map-search-box .float-btn .float-btn-inner img {
    width: 50px;
    height: 50px;
    padding: 10px;
}


/*topbar*/

.topbar.style-2 {
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
}

.topbar.style-2 .right-side li>a:hover {
    color: #fff;
}

.topbar.style-2 .right-side li.cta-btn>a {
    color: #ffffff;
    background: #000;
    padding: 13px 20px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
}

.topbar.style-2 .right-side li.cta-btn:hover>a {
    background-color: #000;
    color: #fff;
}

.topbar {
    padding-bottom: 10px;
}

.topbar .right-side,
.topbar .left-side {
    padding: 10px 0 0;
}

.topbar .left-side ul {
    display: flex;
    align-self: flex-start;
}

.topbar .left-side li {
    margin-right: 20px;
}

.topbar .left-side li a {
    color: #fff;
}

.topbar .left-side li a i {
    margin-right: 5px;
    color: #fff;
}

.topbar .right-side ul {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
}

.topbar .right-side li {
    margin-left: 20px;
    align-self: baseline;
}

.topbar .right-side li.language span {
    margin-right: 5px;
    position: relative;
    top: -1px;
}

.topbar .right-side li.language>select {
    background: transparent;
    color: #fff;
    font-size: 14px;
    border: none;
    -webkit-appearance: none;
    line-height: 1;
}

.topbar .right-side li.language>select>option {
    color: #111111;
}

.topbar .col-lg-6{
  align-self: center;
}

/*Navigation*/

.menu-style {
    transition: 1s all;
}

.menu-style.sticky {
    animation-name: fadeHeaderInDown;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 22;
    background: #000;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.menu-style.sticky .main-menu>li.menu-item>a{
  color: #ffffff;
}
.topbar.fixed-top,
.menu-style.fixed-header {
    position: fixed;
    top: 41px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
}

.topbar.fixed-top {
    top: 0;
    background-color: #111111;
}

.menu-open .menu-style,
.menu-open .menu-style.fixed-header {
    background-color: #000000ab;
}
.menu-open .menu-style.sticky{
  bottom: 0;
}
.menu-style .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-style.style-2 .header {
    justify-content: center;
}

.menu-style .header>.logo {
    width: 120px;
    position: relative;
    top: 0px;
    background-color: #ed673c;
    height: 82px;
}

.menu-style.style-2 .header .right-side .navigation li .logo img,
.menu-style .header>.logo img {
    position: absolute;
    height: 117px;
    z-index: 10;
}

.menu-style.style-2 .header>.logo {
    display: none;
}

.menu-style.style-2 .header .right-side .navigation li .logo {
    width: 120px;
    position: relative;
    top: 0px;
    background-color: #ed673c;
    height: 82px;
    margin: 0 20px;
    display: block;
}

.menu-style.style-2 .header .right-side .navigation li .logo:after,
.menu-style .header>.logo:after {
    content: '';
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    display: block;
    width: 120px;
    left: 0;
    position: absolute;
    height: 35px;
    z-index: 2;
    right: 0;
    bottom: -35px;
}

.menu-style .header .right-side {
    display: flex;
}

.menu-style .header .right-side-2 .cta-btn {
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: relative;
}

.menu-style .header .right-side-2 .cta-btn .cta-text {
    padding: 17px 0px 17px 15px;
}

.menu-style .header .right-side-2 .cta-btn .cta-text:before {
    content: '';
    display: block;
    right: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 0px solid;
    border-left-color: #ed673c;
    border-right-color: #ed673c;
}

.menu-style .header .right-side-2 .cta-btn .cta-text:after {
    content: '';
    display: block;
    background-image: linear-gradient(147deg, #FF2525 0%, #FFE53B 74%);
    width: 1000%;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
}

.menu-style .header .right-side-2 .cta-btn .cta-text .addlisting-btn span {
    margin-left: 10px;
}

.menu-style .header .right-side .hamburger-menu {
    display: none;
    align-items: center;
    cursor: pointer;
}

.menu-style .header .right-side .hamburger-menu .menu-btn {
    transition: 0.3s;
}

.menu-style .header .right-side .hamburger-menu .menu-btn span {
    background: #ff742e;
    height: 2px;
    width: 25px;
    margin-bottom: 5px;
    display: block;
    transform: 0.3s;
}

.menu-style .header .right-side .hamburger-menu .menu-btn span:last-child {
    margin-bottom: 0;
}

.menu-style .header .right-side .hamburger-menu .menu-btn.active {
    position: relative;
    top: -4px;
    transition: 0.3s;
}

.menu-style .header .right-side .hamburger-menu .menu-btn.active span:first-child {
    transform: rotate(45deg);
    transition: 0.3s;
    position: relative;
    top: 7.4px;
    background-color: #fff;
}

.menu-style .header .right-side .hamburger-menu .menu-btn.active span:nth-child(2) {
    display: none;
}

.menu-style .header .right-side .hamburger-menu .menu-btn.active span:last-child {
    transform: rotate(-45deg);
    transition: 0.3s;
    background-color: #fff;
}

.menu-style .header .right-side .navigation .logo {
    display: none;
    margin: 10px 15px;
    width: 120px;
}

.menu-style .header .right-side .navigation .social-media {
    display: none;
    position: fixed;
    left: -320px;
    right: 0;
    width: 320px;
    bottom: 0;
    transition: 0.5s all;
    background: #eee;
}

.menu-style .header .right-side .navigation .social-media ul {
    display: flex;
}

.menu-style .header .right-side .navigation .social-media ul li:first-child {
    border-left: 0;
}

.menu-style .header .right-side .navigation .social-media ul li {
    width: 25%;
    float: left;
    position: relative;
    text-align: center;
    padding: 25px 0;
}

.menu-style .header .right-side .navigation .social-media ul li a {
    display: flex;
    padding: 0;
    width: 25px;
    height: 25px;
    text-align: center;
    margin: 0 auto;
    background: #ed673c;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.menu-style .header .right-side .navigation .social-media ul li a:hover {
    color: #fff;
}

.main-menu {
    display: flex;
}

.main-menu>li.menu-item {
    padding: 10px 0;
}

.main-menu>li.menu-item>a {
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    padding: 18px 20px;
}

.main-menu>li.menu-item>a:after {
    content: '';
    display: block;
    width: 0%;
    height: 4px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.main-menu>li.menu-item:hover>a:after,
.main-menu>li.menu-item.active>a:after {
    width: 70%;
    transition: 0.3s;
}

.main-menu>li.menu-item.active>a {
    color: #ed673c;
}

.menu-item-has-children>a>.arrow:after {
    display: inline-block;
    margin-left: .55em;
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.menu-item-has-children>.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transform: translateY(-10px);
    border-bottom: solid 2px #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .15);
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    width: 250px;
}

.menu-item-has-children:hover>.sub-menu {
    border-top: 1px solid #eee;
    opacity: 1;
    visibility: visible;
    z-index: 999;
    transform: translateY(0px);
    transition: 0.3s;
}

.menu-item-has-children.nav-postion:hover>.sub-menu {
    top: 0;
    transform: translateY(-100%);
}

.menu-item-has-children>.sub-menu>li.menu-item {
    padding: 0 10px;
    background: #000000;
}

.menu-item-has-children.nav-postion>.sub-menu>li.menu-item-has-children>.sub-menu {
    top: inherit;
}

.menu-item-has-children>.sub-menu>li.menu-item>a {
    display: block;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    transition: 0.5s;
    white-space: nowrap;
    color: #ffffff;
}

.menu-item-has-children>.sub-menu>li.menu-item>a:hover{
  color: #ec6d3e;
}

.menu-item-has-children>.sub-menu>li.menu-item:last-child>a {
    border: none;
}

.menu-item-has-children>.sub-menu>li.menu-item:hover>a {
    padding-left: 25px;
    transition: 0.5s;
}

.menu-item-has-children>.sub-menu>li.menu-item-has-children>a>.arrow {
    position: absolute;
    right: 10px;
    transform: rotate(-90deg);
}

.menu-item-has-children>.sub-menu>li.menu-item-has-children>a>.arrow:after {
    display: inline-block;
    margin-left: 0;
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.menu-item-has-children>.sub-menu>li.menu-item-has-children>.sub-menu {
    left: 100%;
    top: -3%;
}

.menu-style.style-2.sticky .header .right-side .navigation li .logo,
.menu-style.sticky .header>.logo {
    height: 60px;
}

.menu-style.style-2.sticky .header .right-side .navigation li .logo img,
.menu-style.sticky .header>.logo img {
    height: 60px;
}

.menu-style.style-2.sticky .header .right-side .navigation li .logo:after,
.menu-style.sticky .header>.logo:after {
    bottom: 0px;
}

.menu-style.sticky .main-menu>li.menu-item {
    padding: 0;
}

.menu-style.sticky .header .right-side-2 .cta-btn .cta-text {
    padding: 7px 0px 7px 15px;
}


/*banner*/


/*simple-banner*/

.simple-banner {
    position: relative;
    height: 800px;
    z-index: 1;
}

.simple-banner .slide-item {
    height: 800px;
    position: relative;
    overflow: hidden;
}

.simple-banner .slide-item:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.simple-banner .slider-btn {
    position: absolute;
    top: unset;
    right: 0;
    bottom: 50px;
}

.simple-banner .slider-btn .swiper-button-next {
    right: 45px;
}

.simple-banner .slider-btn .swiper-button-prev {
    left: auto;
    right: 140px;
}

.simple-banner .slider-btn .swiper-button-next span,
.simple-banner .slider-btn .swiper-button-prev span {
    font-size: 35px;
    padding: 0px 25px;
    color: #fff;
    border: 1px solid #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
}

.simple-banner .slider-btn .swiper-button-prev span:after {
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
}

.simple-banner .slide-item img {
    animation: scale 20s linear infinite;
}

.simple-banner .slide-item .content-wrapper {
    transition: all 1s linear;
    transition-delay: 1s;
}

.simple-banner .slide-item .content-wrapper p,
.simple-banner .slide-item .content-wrapper a,
.simple-banner .slide-item .content-wrapper h1 {
    transition: all .8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
}

.simple-banner .slide-item.swiper-slide-active .content-wrapper a,
.simple-banner .slide-item.swiper-slide-active .content-wrapper p,
.simple-banner .slide-item.swiper-slide-active .content-wrapper h1 {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.simple-banner .slider-search .search-title {
    background-color: #ed673c;
    padding: 15px 30px;
    display: table;
    position: relative;
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;
}

.simple-banner .slider-search .search-title:before {
    border-right: 25px solid transparent;
    content: "";
    left: 20px;
    position: absolute;
    bottom: -30px;
    transform: rotate(20deg);
    border-top: 35px solid #ed673c;
}

.simple-banner .slider-search .search-form {
    margin-top: 30px;
    background-color: rgba(255, 255, 255, .2);
    padding: 30px;
    padding-bottom: 10px;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
}

.simple-banner .slider-search .search-form .form-group button {
    height: 50px;
}

.location-box {
    position: relative;
}

.location-box:before {
    content: "\f05b";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0px, -50%);
    z-index: 0;
    pointer-events: none;
    color: #ed673c;
}


/*banner-style-2*/

.video-banner .jarallax {
    height: 800px;
}

.range-slider {
    display: flex;
}

.range-slider input {
    border: none;
    width: 40px;
    padding: 0;
    margin: -6px 0 0px 15px;
    font-size: 14px;
}

.banner-style-2 .banner-form form .form-group .ui-widget.ui-widget-content {
    margin-bottom: 20px;
}

.ui-widget.ui-widget-content {
    border: 0px solid #c5c5c5;
    background-color: #ddd;
    height: 0.3em;
    margin: 0.85em 0;
}

.ui-widget-header,
.ui-slider-vertical .ui-slider-range-min,
.ui-slider-horizontal .ui-slider-range-min {
    background: #ed673c;
}

.ui-slider-vertical .ui-slider-handle,
.ui-slider-horizontal .ui-slider-handle {
    background: #ed673c;
    font-weight: normal;
    color: #454545;
    border-radius: 1.2em;
    border: 0.4em solid rgba(0, 0, 0, 0);
    cursor: pointer;
    width: 1em;
    height: 1em;
    top: -4px;
}

.ui-slider-vertical .ui-slider-handle.ui-state-active,
.ui-slider-horizontal .ui-slider-handle.ui-state-active {
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    top: -6px;
}

.ui-slider-vertical .ui-slider-handle.ui-state-active,
.ui-slider-vertical .ui-slider-handle {
    top: auto;
}


/*about us*/

.about-left-side {
    margin-left: 35px;
}

.about-left-side .section-header {
    padding-bottom: 0;
    margin: 0 auto 0 0;
    text-align: left;
}

.about-left-side .section-header .section-heading:after {
    margin: 0 auto 0 0;
}

.about-right-side:before {
    content: '';
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    display: block;
    height: 50%;
    z-index: -1;
    position: absolute;
    width: calc(50% + 35px);
    right: 35px;
    left: 0px;
}

.about-right-side>img {
    padding: 0;
    padding-top: 35px;
    padding-left: 35px;
}

.about-right-side .about-meta {
    position: absolute;
    bottom: 59px;
    right: -26px;
    max-width: 380px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    padding: 30px 25px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.about-right-side .about-meta .meta-icon {
    margin-right: 15px;
    width: 47px;
}

.about-right-side .about-meta:before {
    content: '';
    border-width: 20px;
    border-color: #ff2525 transparent transparent #ff2525;
    border-style: solid;
    position: absolute;
    bottom: -40px;
    right: 0;
    z-index: -1;
}

.about-left-side .company-progress .progress-item {
    margin-bottom: 20px;
}

.about-left-side .company-progress .progress-item label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-left-side .company-progress .progress-item .progress {
    border-radius: 0;
    transform: skew(-30deg);
    transform-origin: right bottom;
    margin: 0 0px 0 10px;
    position: relative;
    left: -10px;
}

.about-left-side .company-progress .progress-item .progress .progress-bar {
    background-image: linear-gradient(20deg, #FFE53B 0%, #FF2525 75%);
    position: relative;
    transform: skew(-30deg);
    transform-origin: right bottom;
    margin-left: -10px;
}

.about-left-side .company-progress .progress-item:nth-child(1) .progress .progress-bar {
    width: 70%;
}

.about-left-side .company-progress .progress-item:nth-child(2) .progress .progress-bar {
    width: 80%;
}

.about-left-side .company-progress .progress-item:nth-child(3) .progress .progress-bar {
    width: 75%;
}


/*Categories*/

.project-management-slider {
    margin: 0 -15px;
}

.categories-slider-box .categories-box {
    height: 620px;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
}

.our-services .service-item {
    height: 365px;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
}

.our-services .service-item .text-wrapper,
.categories-slider-box .categories-box .text-wrapper {
    width: 100%;
    background: 0 0;
    background: linear-gradient(to bottom, transparent, #000);
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 1;
    padding: 100px 20px 20px 20px;
}

.our-services .service-item .text-wrapper h5:before,
.categories-slider-box .categories-box .text-wrapper h5:before {
    content: '';
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    width: 60px;
    height: 2px;
    display: block;
    margin-bottom: 20px;
}

.our-services .service-item .text-wrapper h5,
.categories-slider-box .categories-box .text-wrapper h5 {
    position: relative;
    text-transform: uppercase;
    transform: translateY(20px) translateZ(0);
    transition: all .3s cubic-bezier(.77, 0, .175, 1);
}

.our-services .service-item .text-wrapper p,
.categories-slider-box .categories-box .text-wrapper p {
    opacity: 0;
    color: #fff;
    transform: translateY(20px) translateZ(0);
    transition: all .3s cubic-bezier(.77, 0, .175, 1);
}

.our-services .service-item:hover .text-wrapper h5,
.categories-slider-box .categories-box:hover .text-wrapper h5 {
    transform: translateY(-5px) translateZ(0);
}

.our-services .service-item:hover .text-wrapper p,
.categories-slider-box .categories-box:hover .text-wrapper p {
    opacity: .8;
    transition-delay: .2s;
    transform: translateY(0) translateZ(0);
}

.categories-tabs {
    margin-top: -40px;
}

.categories-tabs .nav {
    border: none;
    box-shadow: 0 6px 19px rgba(0, 0, 0, .08);
}

.categories-tabs .nav .nav-item {
    width: 25%;
}

.categories-tabs .nav .nav-item .nav-link {
    margin: 0 auto;
    border: none;
    padding: 40px 20px;
    background: #fff;
    border-radius: 0;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.categories-tabs .nav .nav-item .nav-link span {
    display: block;
    font-size: 60px;
    line-height: 1;
    width: 60px;
    margin: 0 auto 10px;
}

.categories-tabs .nav .nav-item .nav-link.active {
    color: #ed673c;
}

.side-video-wrapper .video_wrapper {
    height: 370px;
}


/*recent-property*/

.property-grid-box {
    position: relative;
}

.property-grid-box:hover {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
}

.property-grid-box .property-grid-wrapper {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
    border-radius: 3px;
}

.property-grid-box .property-grid-wrapper .property-img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.property-grid-box .property-grid-wrapper .property-img .property-type {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
}

.property-grid-box .property-grid-wrapper .property-img .property-address {
    position: absolute;
    bottom: 0;
    padding: 0 10px;
    z-index: 10;
}

.property-grid-box .property-grid-wrapper .property-img .property-address a {
    color: #fff;
}

.property-grid-box .property-grid-wrapper .property-img .property-address a i {
    margin-right: 5px;
}

.property-grid-box .property-grid-wrapper .property-img .property-save-btn {
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
}

.property-grid-box:hover .property-grid-wrapper .property-img .property-save-btn {
    opacity: 1;
    visibility: visible;
    transition: 0.3s all;
}

.property-grid-box .property-grid-wrapper .property-img .property-save-btn ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.property-grid-box .property-grid-wrapper .property-img .property-save-btn ul li {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 0.57);
    border-radius: 50%;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    margin: 0 2px;
}

.property-grid-box .property-grid-wrapper .property-img .property-save-btn ul li a i {
    color: #ccc;
    font-size: 16px;
    line-height: 36px;
}

.property-grid-box .property-grid-wrapper .property-img .property-address:after {
    position: absolute;
    content: '';
    display: block;
    left: 100%;
    top: 0;
    border-top: 21px solid transparent;
    border-left: 21px solid;
    border-left-color: #ed673c;
}

.property-grid-box .property-grid-wrapper .property-img>a {
    display: block;
}

.property-grid-box .property-grid-wrapper .property-img>a:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    z-index: 9;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 5%, rgba(22, 22, 23, 0) 40%, rgba(0, 0, 0, 0) 100%);
    transition: 0.7s;
}

.property-grid-box .property-grid-wrapper .property-grid-caption {
    background-color: #fff;
    border-radius: 3px;
    padding-bottom: 0;
}

.property-grid-box .property-grid-wrapper .property-grid-caption h5 {
    font-size: 20px;
}

.property-grid-box .property-grid-wrapper .property-grid-caption .price {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 0;
    font-weight: 500;
}

.property-grid-box .property-grid-wrapper .property-grid-caption .price i {
    margin-right: 5px;
}

.property-grid-box .property-grid-wrapper .property-grid-caption .price span {
    font-size: 80%;
    margin-left: 4px;
}

.property-grid-box .property-grid-wrapper .property-grid-caption .property-feature {
    columns: 2;
    padding-bottom: 10px;
}

.property-grid-box .property-grid-wrapper .property-grid-caption .property-feature li {
    padding: 0px 0 10px 0;
}

.property-grid-box .property-grid-wrapper .property-grid-caption .property-feature li i {
    font-size: 18px;
    margin-right: 5px;
    color: #000000;
}

.property-grid-box .property-grid-wrapper .property-grid-caption .property-feature li span {
    font-size: 14px;
    color: #111111;
    font-weight: 400;
}

.property-grid-box .property-grid-wrapper .property-grid-footer {
    padding: 20px;
    background: #fff;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-list-footer .link-btn span,
.property-grid-box .property-grid-wrapper .property-grid-footer>a span {
    transition: 0.5s all;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-list-footer>.link-btn:hover span,
.property-grid-box .property-grid-wrapper .property-grid-footer>a:hover span {
    padding-left: 8px;
    transition: 0.5s all;
}

.ratings span {
    cursor: pointer;
}

.ratings .rating-text {
    display: block;
    font-weight: 500;
    text-align: center;
}


/*browse-listing-gallery*/

.browse-listing-gallery .browse-gallery>.row>div {
    padding: 7.5px;
}

.browse-listing-gallery .browse-gallery .gallery-img {
    position: relative;
    transition: all 0.4s ease;
    box-shadow: 0 4px 16px -2px rgba(0, 0, 0, .1), 0 0 0 1px rgba(0, 0, 0, .02);
}

.browse-listing-gallery .browse-gallery .gallery-img .img-overlay {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
    top: 0px;
    left: 0px;
    transition: all 0.4s ease;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
}

.browse-listing-gallery .browse-gallery .gallery-img:hover .btn-border {
    background-color: #fff;
    color: #111111;
    transition: 0.5s all;
}

.browse-listing-gallery .browse-gallery .gallery-img:hover .img-overlay {
    opacity: 0.15;
}

.browse-listing-gallery .browse-gallery .gallery-img:hover .img-overlay:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    transform: skewX(-25deg);
    animation: shine .75s;
}


/*our services*/

.our-services .filter-gallery>ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.our-services .filter-gallery>ul>li {
    width: 100%;
}

.custom-tabs>.nav>.nav-item>.nav-link,
.our-services .filter-gallery>ul>li>a {
    padding: 8px 25px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    display: block;
    z-index: 10;
    text-align: center;
    background-color: transparent;
    border: 1px solid #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    transition: 0.5s all;
    margin: 0 0px 10px;
    border-radius: 0px;
}

.custom-tabs>.nav>.nav-item>.nav-link:before,
.our-services .filter-gallery>ul>li>a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 10%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    transition: 0.5s all;
}

.custom-tabs>.nav>.nav-item:hover>.nav-link,
.custom-tabs>.nav>.nav-item>.nav-link.active,
.our-services .filter-gallery>ul>li.active>a,
.our-services .filter-gallery>ul>li:hover>a {
    color: #fff;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    transition: 0.5s all;
}

.custom-tabs>.nav>.nav-item:hover>.nav-link:before,
.custom-tabs>.nav>.nav-item>.nav-link.active:before,
.our-services .filter-gallery>ul>li.active>a:before,
.our-services .filter-gallery>ul>li:hover>a:before {
    width: 100%;
}

.our-services .service-item .text-wrapper {
    padding-bottom: 20px;
}

.our-category {
    background-color: #ffffff;
    background-image: url("../images/bg-bw.jpg");
    background-attachment: fixed;
    background-size: cover;
}


/*our history*/

.video_wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #000;
    height: 400px;
}

.videoIframe {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}

.videoPoster img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: none;
    background-position: center;
    overflow: hidden;
    opacity: 1;
    transition: opacity 100ms, height 0s;
    transition-delay: 0s, 0s;
}

.video-wrapper .video_wrapper .videoPoster {
    background-image: url(https://via.placeholder.com/750x400);
}

.videoPoster:hover {
    cursor: pointer;
}

.videoPoster .video-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.videoWrapperActive .videoPoster {
    opacity: 0;
    height: 0;
    transition-delay: 0s, 800ms;
}

.video-btn-wrapper {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    font-size: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height .25s ease, width .25s ease;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.video-btn-wrapper>a>i {
    font-weight: 600;
    vertical-align: middle;
}

.video-btn-wrapper:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid #fff;
    animation: ripple 2s linear infinite;
}

.video-btn-wrapper .video-btn {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: inherit;
    font-size: 30px;
}

.custom-tabs>.nav {
    border: none;
    justify-content: space-between;
    position: relative;
}

.custom-tabs>.nav:before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 100%;
    height: 1px;
    background-color: #ed673c
}

.custom-tabs>.nav>.nav-item>.nav-link {
    background-color: #f4f4f4;
    margin: 0;
}

.bx-wrapper {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05);
}

.our-history {
    background-image: url(../images/h3-bg-section-01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.our-history .tab-inner .content-box .list {
    margin: 10px 0 15px;
}

.our-history .tab-inner .content-box .list ul {
    columns: 2;
}

.our-history .tab-inner .content-box .list ul li {
    margin-bottom: 15px;
}

.our-history .tab-inner .content-box .list ul li i {
    margin-right: 10px;
}

.conpany-history-style .com-his-left-side .section-header {
    margin: 0 auto 0 0;
    text-align: left;
}

.conpany-history-style .com-his-left-side .section-header .section-heading:after {
    margin: 0 auto 0 0;
}

.conpany-history-style .com-his-left-side .com-his-desc .com-his-box.img,
.conpany-history-style .com-his-right-side {
    overflow: hidden;
    z-index: 1;
}

.conpany-history-style .com-his-left-side .com-his-desc .com-his-box.img:before,
.conpany-history-style .com-his-right-side:before {
    content: '';
    display: block;
    position: absolute;
    left: 30px;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: repeating-linear-gradient(-45deg, #fff0, #fff0 2.5px, #ed673c 5px, #ed673c 0);
}

.conpany-history-style .com-his-right-side>img {
    padding-top: 30px;
}

.conpany-history-style .com-his-left-side .com-his-desc .com-his-box.img:before {
    left: 0;
    width: calc(100% - 30px);
}

.conpany-history-style .com-his-left-side .com-his-desc h4 {
    font-size: 24px;
}

.conpany-history-style .com-his-left-side .com-his-desc .com-his-box.img>img {
    padding-bottom: 30px;
}

.conpany-history-style .com-hist-left-side .com-his-desc .com-his-box {
    padding-right: 6%;
}


/*full-services-sec*/

.full-services-sec .left-side .service-text {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 400px;
    height: 300px;
}

.full-services-sec .left-side .service-text .text-wrapper {
    padding: 40px;
}

.full-services-sec .left-side .service-text .text-wrapper .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.full-services-sec .left-side .service-text-2 {
    position: absolute;
    top: 0;
    padding: 40px;
    overflow: hidden;
    max-width: 215px;
    left: 0;
    right: 0;
}

.full-services-sec .left-side .service-text-2 h2 {
    font-size: 55px;
    line-height: 1;
}

.full-services-sec .left-side .service-text-2 h5 {
    font-size: 30px;
    font-weight: 400;
}

.full-services-sec .left-side .video-play-btn {
    position: absolute;
    bottom: 0;
    max-width: 500px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.full-services-sec .left-side .video-play-btn a {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: 2px solid #000000;
    margin-left: 15px;
    color: #000000;
    font-size: 25px;
}

.full-services-sec .right-side {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    height: 100%;
    align-self: center;
}

.full-services-sec .right-side .text-wrapper h2 {
    line-height: 1.8;
}

.full-services-sec .right-side .text-wrapper .right-side-box {
    margin-bottom: 30px;
    text-align: left;
    transition: 0.3s all;
}

.full-services-sec .right-side .text-wrapper .right-side-box:hover .icon {
    animation: slideTopDown 1s infinite alternate;
    transition: 0.3s all;
}

.full-services-sec .right-side .text-wrapper .right-side-box .icon {
    color: #fff;
    margin-bottom: 10px;
    font-size: 45px;
    width: 45px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: 0.3s all;
}


/*our agents*/

.our-team {
    background-image: url(../images/homepage-1/agent-bg-1920x780.jpg);
    position: relative;
    z-index: 9;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.our-team:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(244, 244, 244);
    z-index: -10;
    opacity: 0.8;
}

.our-team .team-block .inner-box {
    position: relative;
    background-color: #ffffff;
    transition: all 500ms ease;
}

.our-team .team-block .lower-content {
    padding-top: 20px;
    overflow: hidden;
    border: 1px solid #ed673c;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: relative;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
}

.our-team .team-block .lower-content h4 {
    margin-bottom: 5px;
    margin-top: -5px;
}

.our-team .team-block .lower-content h4 a {
    color: #ffffff;
}

.our-team .team-block .image ul.team-social {
    background-color: #fff;
    position: absolute;
    bottom: -35px;
    left: -1px;
    right: -1px;
    margin: auto;
    width: auto;
    transition: all 0.9s ease;
    text-align: center;
    columns: 4;
}

.our-team .team-block .image ul.team-social>li>a {
    width: 100%;
    height: 34px;
    text-align: center;
    font-size: 16px;
    line-height: 34px;
    color: #ed673c;
}

.our-team .team-block .image ul.team-social>li:hover>a {
    color: #fff;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
}

.our-team .team-block:hover .image .team-social {
    bottom: 0px;
}


/*blog*/

.our_articles .post {
    position: relative;
}

.our_articles .post .post-wrapper {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
    border-radius: 3px;
    position: relative;
}

.our_articles .post:hover .post-wrapper {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
}

.our_articles .post .post-wrapper .blog-img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.our_articles .post .post-wrapper .post-date {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 1;
}

.our_articles .post .post-wrapper .blog-img .post-video {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
    justify-content: center;
    display: flex;
}

.our_articles .post .post-wrapper .blog-img .post-video .video-btn-wrapper {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    font-size: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height .25s ease, width .25s ease;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.our_articles .post .post-wrapper .blog-img .post-video .video-btn-wrapper a{
  line-height: 1.2;
}
.our_articles .post .post-wrapper .blog-img .post-video .video-btn-wrapper:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    animation: ripple 2s linear infinite;
}

.our_articles .post .post-wrapper .blog-img .post-video .video-btn-wrapper .video-btn {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: inherit;
    font-size: 30px;
}

.our_articles .post .post-wrapper .post-date>a {
    padding: 8px 15px;
    line-height: 10px;
    font-size: 14px;
    position: relative;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    color: #fff;
    float: right;
}

.our_articles .post .post-wrapper .blog-meta .cat-box {
    position: relative;
    margin-top: -40px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    margin-bottom: 20px;
    padding: 5px 10px;
    width: fit-content;
    z-index: 1;
}

.our_articles .post .post-wrapper .blog-meta .cat-box .cats a {
    color: #fff;
    font-size: 16px;
    position: relative;
}

.our_articles .post .post-wrapper .blog-meta .cat-box .cats a:after {
    content: '/';
    margin: 0 5px;
    position: relative;
    top: 1px;
}

.our_articles .post .post-wrapper .blog-meta .cat-box .cats a:last-child:after {
    display: none;
}

.our_articles .post .post-wrapper .blog-meta .post-title {
    font-size: 20px;
}

.our_articles .post .post-wrapper .blog-footer-meta {
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.our_articles .post .post-wrapper .blog-footer-meta .post-author {
    display: flex;
    align-items: center;
}

.our_articles .post .post-wrapper .blog-footer-meta .post-author .author-img {
    margin-right: 15px;
}

.our_articles .post .post-wrapper .blog-footer-meta .post-link>a {
    text-decoration: underline;
    text-transform: uppercase;
}


/*testimonials / clients*/

.testimonials-box {
    height: 100%;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
}

.testimonials-box,
.clients-box {
    padding-top: 80px;
    padding-bottom: 80px;
}

.faqs-box .section-header,
.testimonials-box .section-header {
    text-align: left;
    margin: 0;
}

.testimonials-box .section-header .section-heading:after {
    margin: 0 auto 0 0;
    background-image: linear-gradient(45deg, black, #ed6d3f);
}

.testimonials-box .testimonials-wrapper .testimonials-author {
    width: 120px;
    height: 120px;
    box-shadow: 0 0 0 4px #111111 inset;
    padding: 4px;
    margin: 0 auto;
}

.testimonials-box .testimonials-wrapper .testimonial-nav .slick-track {
    padding: 20px 0;
}

.testimonials-box .testimonials-wrapper .testimonial-nav .slick-current {
    transform: scale(1.35);
    transition: all 260ms;
}

.testimonials-box .testimonials-wrapper .testimonial-nav .slick-current .testimonials-author {
    box-shadow: 0 0 0 4px #fff inset;
    transform: scale3d(1, 1, 1);
    transition: all 260ms;
}

.testimonials-box .testimonials-wrapper .testimonials-inner {
    position: relative;
    padding: 0 0 0 100px;
}

.testimonials-box .testimonials-wrapper .testimonials-inner:before {
    display: block;
    position: absolute;
    margin: 0px;
    left: 0;
    top: 0;
    color: #e8e8e8;
    font-family: 'Font Awesome 5 Brands';
    font-weight: 900;
    content: "\f099";
    line-height: 1;
    font-size: 76px;
}

.testimonials-box .testimonials-wrapper .testimonials-inner .testimonial-content {
    max-width: 550px;
}

.testimonials-box .testimonials-wrapper .testimonials-inner .testimonial-content strong,
.testimonials-box .testimonials-wrapper .testimonials-inner .testimonial-content h4 {
    font-weight: 600;
}

.clients-box .section-header-right {
    margin: 0 0 0 auto;
}

.clients-box .clients-wrapper {
    position: relative;
    overflow: hidden;
}

.clients-box .clients-wrapper .client-box {
    float: left;
    position: relative;
    padding: 0;
    overflow: hidden;
    width: calc(33.3% - 10px);
    margin: 5px;
}

.clients-box .clients-wrapper .client-box .client-item {
    display: block;
    position: relative;
    opacity: 1;
    transition: all 350ms ease;
    width: 100%;
    height: 250px;
}


/*partners*/

.clients-wrapper-side {
    position: relative;
    overflow: hidden;
}

.clients-wrapper-side .client-box {
    float: left;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    margin: 0 0 -1px -1px;
    position: relative;
    padding: 0;
    width: 33.3%;
}

.clients-wrapper-side .client-box .client-item {
    display: block;
    margin: 15% auto;
    position: relative;
    opacity: .7;
    transition: all 350ms ease;
    width: 125px;
    height: 125px;
}

.clients-wrapper-side .client-box .client-item:hover {
    opacity: 1;
}


/*Faqs / Instagram*/

.faqs-box .section-header .section-heading:after {
    margin: 0 auto 0 0;
}

.schdule-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000000;
    padding: 40px 20px;
    margin-bottom: 60px;
}

.schdule-box .right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.schdule-box .right-side a:before {
    display: none;
}

.instagram-sec>.row {
    margin: 0 -5px;
}

.instagram-sec>.row>div {
    padding: 5px;
}

.instagram-sec .insta-img {
    width: 100%;
    height: 155px;
}

.testimonial-box .testimonials-inner {
    height: 335px;
    overflow: hidden;
}

.testimonial-box .testimonials-inner .testimonials-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.testimonial-box .testimonials-inner .testimonials-item .testimonials-img {
    border: 10px solid #eee;
    border-radius: 50%;
}

.testimonial-box .testimonials-inner .testimonials-item .testimonials-content {
    width: 100%;
    background: #eee;
    padding: 18px 10px;
    border-bottom: 2px solid #c1c0c0;
    margin-left: -40px;
    z-index: -1;
    padding-left: 40px;
}

.testimonial-box .testimonials-inner .testimonials-item .testimonials-content .testimonials-text .content {
    display: flex;
}

.testimonial-box .testimonials-inner .testimonials-item .testimonials-content .testimonials-text .content i {
    padding-left: 10px;
    font-size: 20px;
    margin-right: 10px;
    color: #ccc;
}


/*download-app*/

.download-app {
    background-image: url(../images/homepage-1/free-consultation-bg.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: center;
}

.download-app:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    opacity: 0.8;
    z-index: 1;
}

.download-app .app-img {
    z-index: 10;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.download-app .app-img-btm {
    z-index: 10;
    position: relative;
}

.download-app .app-img img {
    margin: 0 10px 50px;
}

.download-app .app-img a:first-child img{
  margin-left: 0;
}

/*call-to-action*/

.bg-call-to-action {
    background-image: url(../images/homepage-1/free-consultation-bg.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: center;
}

.consult-form .nice-select span {
    color: #000;
}

.consult-form .nice-select.open span {
    color: #111111;
}

.consult-form input.form-control-custom::placeholder,
.consult-form input.form-control-custom {
    color: #000000;
    background: transparent;
}

.consult-form .form-control-custom:focus {
    background: transparent;
}

.footer .footer-box .newsletter button,
.consult-form button {
    color: #f64831;
}


/*footer*/

.text-marqueee {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    pointer-events: none;
    user-select: none;
    margin-bottom: 80px;
}

.text-marqueee .marquee-track {
    display: inline-flex;
    animation: marqueeAnimation 50s linear infinite;
}

.text-marqueee .marquee-track h2 {
    font-size: 6.25rem;
    margin: 0 10px;
}

.footer .text-marqueee .marquee-track h2 {
    font-size: 6.25rem;
}

.text-marqueee .marquee-track .text-outline {
    -webkit-text-stroke-color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: .012em;
}

.footer .footer-box .footer-heading {
    position: relative;
    margin-bottom: 20px;
}

.footer .footer-box .footer-heading:after {
    content: "";
    height: 5px;
    width: 80px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    display: block;
    margin-top: 20px;
}

.footer .footer-box .links li {
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 25px;
}

.footer .footer-box .links li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    height: 3px;
    width: 15px;
}

.footer .footer-box .popular_post li:last-child,
.footer .footer-box .links li:last-child {
    margin-bottom: 0;
}

.footer .footer-box .insta-feed {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin: 0px;
}

.footer .footer-box .popular_post li,
.footer .footer-box .newsletter .form-group {
    margin-bottom: 15px;
}

.footer .footer-box .social-media ul {
    margin-top: 15px;
    display: flex;
}

.footer .footer-box .social-media ul li a {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ed673c;
    margin: 0px 5px 0 0;
    border: 1px solid #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
}

.footer .footer-box .social-media ul li a:hover {
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    color: #fff;
}

.footer .footer-box .social-media ul li:hover a {
    transform: rotate(360deg);
}

.footer .footer-box .insta-feed .insta-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 2px;
}

.footer .footer-box .popular_post li .post .post-wrapper {
    width: 100%;
    display: flex;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_img {
    position: relative;
    overflow: hidden;
    flex: 0 0 80px;
    max-width: 80px;
    height: 80px;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_img img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_title {
    flex: 0 0 calc(100% - 80px);
    max-width: calc(100% - 80px);
    padding: 2px 15px;
    padding-right: 0;
    width: 100%;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_title h6 {
    margin-bottom: 10px;
    line-height: 1.3;
}

.footer .footer-box .popular_post li .post .post-wrapper .popular_post_title h6 a {
    font-weight: 500;
}

.nice-select:hover {
    border-color: #000000;
}
.newsletter .form-control-custom {
    border: 2px solid #ffffff;
}
.footer .footer-box .newsletter button:hover,
.consult-form button:hover{
  color: #fff;
}
/*copyright*/

.copyright {
    padding: 20px 0;
    background: #000000;
    border-top: 1px solid #f0f0ff7a;
}

.copyright .payment-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.copyright p {
    text-align: right;
}


/*back to top*/

#back-top {
    text-align: center;
    display: none;
}

#back-top a {
    display: block;
    border-radius: 0px;
    line-height: 25px;
    border: #ed673c solid 1px;
    color: #ffffff;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    transition-duration: 0.3s;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px;
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    padding: 0 10px 7px;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
}

#back-top a span {
    font-size: 14px;
    line-height: 1;
}


/*======================
3. Blog
========================*/


/*subheader*/

.subheader {
    background: url(../images/about-us/banner-1920x265.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.subheader:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.subheader .breadcrumb-wrapper .page-title h1,
.subheader .breadcrumb-wrapper .custom li a {
    color: #ffffff;
}

.breadcrumb-wrapper .page-title {
    position: relative;
    padding: 10px 0;
    padding-left: 25px;
    margin-bottom: 20px;
}


.breadcrumb-wrapper .page-title:after {
    content: "";
    height: calc(100% - 6px);
    width: 5px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: absolute;
    top: 3px;
    left: 0;
}

.breadcrumb-wrapper .page-title h1 {
    text-transform: capitalize;
    font-size: 36px;
    line-height: 1;
    font-weight: 700;
    position: relative;
    margin: 0;
}

.breadcrumb-wrapper .breadcrumb {
    background: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0;
}

.breadcrumb-wrapper .breadcrumb li {
    font-size: 15px;
    color: #111111;
    text-transform: capitalize;
    font-weight: 500;
}

.breadcrumb-wrapper .breadcrumb li a:after {
    content: '-';
    color: #ed673c;
    font-size: 14px;
    margin: 0 5px;
}

.breadcrumb-wrapper .breadcrumb li.active {
    color: #ed673c;
}


/*sidebar*/

.sidebar_wrap .sidebar .sidebar_widgets {
    padding: 20px;
    background: #fff;
    border: #efefef solid 1px;
    border-radius: 3px;
}

.sidebar_wrap .sidebar .sidebar_widgets .widget_title {
    margin: -20px -20px 20px;
    padding: 20px;
    position: relative;
}

.sidebar_wrap .sidebar .sidebar_widgets .widget_title h5 {
    font-size: 20px;
}

.sidebar_wrap .sidebar .sidebar_widgets .widget_title:after {
    content: "";
    height: calc(100% - 0px);
    width: 5px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: absolute;
    top: 0px;
    left: 0;
}

.sidebar_wrap .sidebar .sidebar_widgets .categories li {
    position: relative;
    margin-bottom: 10px;
}

.sidebar_wrap .sidebar .sidebar_widgets .categories li:last-child {
    margin-bottom: 0px;
}

.sidebar_wrap .sidebar .sidebar_widgets .categories li a {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper {
    width: 100%;
    display: flex;
    box-shadow: none;
}

.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post:hover .post-wrapper {
    box-shadow: none;
}

.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper .popular_post_img {
    position: relative;
    overflow: hidden;
    flex: 0 0 80px;
    max-width: 80px;
    height: 80px;
}

.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper .popular_post_title {
    flex: 0 0 calc(100% - 80px);
    max-width: calc(100% - 80px);
    padding: 5px 15px;
    width: 100%;
}

.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper .popular_post_title h6 {
    margin-bottom: 10px;
    line-height: 1.3;
    font-size: 14px;
}

.sidebar_wrap .sidebar .sidebar_widgets .tags a {
    display: inline-block;
    padding: 5px 15px;
    border: #ccc solid 1px;
    margin: 0 5px 10px 0;
    background: #ffffff;
    font-weight: 500;
    color: #111111;
}

.sidebar_wrap .sidebar .sidebar_widgets .tags a:hover {
    border-color: #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    color: #ed673c;
}


/*======================
4. Blog Details
========================*/

.blog-details .post .post-wrapper .blog-img,
.blog-details .post .post-wrapper .blog-img .swiper-slide {
    position: relative;
    width: 100%;
    height: 450px;
}

.blog-details .post .post-wrapper .blog-meta .blog-title {
    position: relative;
    padding: 10px 0;
    padding-left: 25px;
    margin-bottom: 20px;
}

.blog-details .post .post-wrapper .blog-meta .blog-title:after {
    content: "";
    height: calc(100% - 6px);
    width: 5px;
    background-color: #ed673c;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: absolute;
    top: 3px;
    left: 0;
}

.blog-details .post .post-wrapper .blog-meta .blog-title h2 {
    font-size: 30px;
    text-transform: capitalize;
    margin: 0;
}

.blog-details .post .post-wrapper .blog-img .post-date {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    z-index: 1;
}

.blog-details .post .post-wrapper .blog-img .post-date>a {
    padding: 15px;
    line-height: 10px;
    font-size: 14px;
    position: relative;
    background-color: #ed673c;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    color: #fff;
    float: right;
}

.blog-details .post .post-wrapper .blog-img .post-video {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
    justify-content: center;
    display: flex;
}

.blog-details .post .post-wrapper .blog-img .post-video .video-btn-wrapper {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    font-size: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height .25s ease, width .25s ease;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.blog-details .post .post-wrapper .blog-img .post-video .video-btn-wrapper:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    animation: ripple 2s linear infinite;
}

.blog-details .post .post-wrapper .blog-img .post-video .video-btn-wrapper .video-btn {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: inherit;
    font-size: 30px;
}

.blog-details .post .post-wrapper .blog-meta .quote-box blockquote {
    background-image: url(../images/default_pattern.png);
    padding-left: 45px;
    position: relative;
}

.blog-details .post .post-wrapper .blog-meta .quote-box blockquote .quote-icon {
    background: linear-gradient(to top right, transparent 0, transparent 50%, #eb7140 51%, #ff2525 100%);
    width: 75px;
    height: 75px;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 2px 10px;
}

.blog-details .post .post-wrapper .post-meta,
.blog-details .post .post-wrapper .post-meta .post-author {
    display: flex;
    align-items: center;
    margin-right: 15px;
    flex-wrap: wrap;
    width: auto;
    border: none;
    padding: 0;
    background: none;
}

.blog-details .post .post-wrapper .post-meta .post-author:before {
    display: none;
}

.blog-details .post .post-wrapper .post-meta {
    margin-right: 0;
    justify-content: space-between;
}

.blog-details .post .post-wrapper .post-meta .post-author .author-img {
    margin-right: 15px;
    flex: 0 0 40px;
    max-width: 40px;
    height: 40px;
}

.blog-details .post .post-wrapper .post-meta .post-content span:last-child {
    margin-right: 0;
}

.blog-details .post .post-wrapper .post-meta .post-content span {
    margin-right: 20px;
}

.blog-details .post .post-wrapper .blog-content .description {
    margin-bottom: 20px;
}

.blog-details .post .post-wrapper .blog-content .blog-video .video_wrapper {
    height: 450px;
}

.blog-details .post-details-tags-social {
    padding-top: 5px;
}

.blog-details .post-details-tags-social .tags-box {
    display: flex;
    align-items: flex-start;
}

.blog-details .post-details-tags-social .tags {
    margin-left: 15px;
}

.blog-details .post-details-tags-social .tags a {
    display: inline-block;
    padding: 5px 15px;
    border: #ccc solid 1px;
    margin: 0 5px 0px 0;
    background: #ffffff;
    font-weight: 500;
    color: #111111;
}

.blog-details .post-details-tags-social .tags a:hover {
    border-color: #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
    color: #ed673c;
}

.blog-details .social-media-box>ul {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
}

.blog-details .social-media-box>ul>li {
    padding-left: 0;
    margin: 0;
    margin-left: 10px;
}

.blog-details .social-media-box>ul>li>a {
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: none;
    box-shadow: 4px 3px 10px 0px rgba(0, 0, 0, 0.08);
    padding: 10px 14px;
}

.blog-details .social-media-box>ul>li>a.fb {
    color: #4661c5;
}

.blog-details .social-media-box>ul>li>a.fb:hover {
    color: #fff;
    background: #4661c5;
}

.blog-details .social-media-box>ul>li>a.tw {
    color: #45a4e6;
}

.blog-details .social-media-box>ul>li>a.tw:hover {
    color: #fff;
    background: #45a4e6;
}

.blog-details .social-media-box>ul>li>a.ln {
    color: #007bb5;
}

.blog-details .social-media-box>ul>li>a.ln:hover {
    color: #fff;
    background: #007bb5;
}

.blog-details .social-media-box>ul>li>a.gg {
    color: #d6463a;
}

.blog-details .social-media-box>ul>li>a.gg:hover {
    color: #fff;
    background: #d6463a;
}

.blog-details .social-media-box>ul>li>a:hover {
    background-color: #73be20;
    border-color: #73be20;
}

.blog-details .post-author {
    border: 0px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px;
    padding-left: 30px;
    width: 100%;
    background-image: url(../images/default_pattern.png);
}

.blog-details .post .post-wrapper .blog-meta .quote-box blockquote:before,
.blog-details .post-author:before {
    content: '';
    height: 100%;
    width: 15px;
    background-color: #fd7435;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
}

.blog-details .post-author .author-img {
    position: relative;
    overflow: hidden;
    flex: 0 0 150px;
    max-width: 150px;
    height: 150px;
    margin-right: 15px;
}

.blog-details .post-author .author-caption {
    flex: 0 0 calc(100% - 165px);
    max-width: calc(100% - 165px);
    width: 100%;
}

.blog-details .post-author .author-caption h5 {
    margin-bottom: 10px;
}

.blog-details .post-author .author-caption .social-media-box,
.blog-details .post-author .author-caption .social-media-box ul {
    justify-content: flex-start;
}

.blog-details .post-author .author-caption .social-media-box ul li {
    margin-left: 0;
    margin-right: 10px;
}

.pagination-btn nav ul {
    justify-content: space-between;
}

.comment-box .children,
.comment-box .comments {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 35px;
    list-style: inherit;
}

.comment-box .children {
    padding-left: 95px;
}

.comment-box .comments li.comment {
    list-style: outside none none;
    padding-left: 0;
    margin-bottom: 20px;
}

.comment-box .comments li article {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.comment-box .comments li article .comment-avatar {
    margin-right: 20px;
    float: left;
    overflow: hidden;
    height: 80px;
    border-radius: 50px;
}

.comment-box .comments li article .comment-content {
    float: right;
    width: calc(100% - 110px);
}

.comment-box .comments li article .comment-content .comment-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.comment-box .comments li article .comment-content .comment-meta .comment-meta-reply .comment-reply-link {
    font-size: 20px;
    padding: 0 15px;
}

.comment-box .comments li article .comment-content .comment-meta .comment-meta-header .post-date .date {
    display: inline-block;
    padding: 8px 15px;
    position: relative;
    line-height: 10px;
    font-size: 14px;
    position: relative;
    color: #fff;
}

.section-description .input-group .input-group-append{
    margin-left: 10px;
}

/*======================
5. Listing
========================*/

.center-hypen {
    position: relative;
}

.center-hypen:after {
    content: '-';
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 25px;
    color: #111111;
}

.sidebar_wrap .sidebar .listing-categories li:after {
    content: "\f162";
    font-family: "Flaticon";
    font-weight: 900;
    position: absolute;
    left: 0;
    color: #ed673c;
    font-size: 14px;
}

.sidebar_wrap .sidebar .listing-categories li {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.sidebar_wrap .sidebar .listing-categories li:hover a {
    padding-left: 15px;
    transition: 0.3s all;
}

.sidebar_wrap .sidebar .listing-categories li:last-child {
    margin-bottom: 0;
    border-bottom: 0px solid #ccc;
    padding-bottom: 0px;
}

.sidebar_wrap .sidebar .property-filter .range-slider {
    align-items: center;
    justify-content: space-between;
}

.sidebar_wrap .sidebar .property-filter .range-slider input {
    margin-left: 0;
}

.sidebar_wrap .sidebar .property-filter .more-filter button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar_wrap .sidebar .property-filter .more-filter button:after {
    position: relative;
    content: '-';
    background: #fff;
    border: 1px solid #eee;
    color: #111111;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.sidebar_wrap .sidebar .property-filter .more-filter button.collapsed:after {
    content: '+';
}

.sidebar_wrap .sidebar .property-filter .more-filter form {
    margin-top: 20px;
}

.listing-top-heading {
    border-bottom: 2px solid #ed673c;
    background: #fff;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listing-top-heading h6 {
    padding: 20px 0;
}

.listing-top-heading .sort-by {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.listing-top-heading .sort-by span {
    white-space: nowrap;
    margin-right: 15px;
    color: #000;
}

.listing-top-heading .sort-by .custom-select {
    margin-bottom: 0;
    background: #fff;
}

.listing-top-heading .listing-view .nav-tabs {
    display: flex;
    width: 80px;
    border: none;
}

.listing-top-heading .listing-view .nav-tabs .nav-item {
    margin-left: 15px;
}

.listing-top-heading .listing-view .nav-tabs .nav-item .nav-link {
    font-size: 25px;
    padding: 0;
    border-radius: 0;
    border: none;
    background: transparent;
    color: #111111;
}

.listing-top-heading .listing-view .nav-tabs .nav-item .nav-link.active {
    color: #ed673c;
}


/*property-list-view*/

.img-overlay-new:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    z-index: 9;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 5%, rgba(22, 22, 23, 0) 40%, rgba(0, 0, 0, 0) 100%);
    transition: 0.7s;
}

.property-list-view .property-list-wrapper {
    display: flex;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
}

.property-list-view:hover {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
}

.property-list-view .property-list-wrapper .property-list-img {
    flex: 0 0 280px;
    max-width: 280px
}

.property-list-view .property-list-wrapper .property-list-img .property-type {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
}

.property-list-view .property-list-wrapper .property-list-img .property-save-btn {
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
}

.property-list-view .property-list-wrapper .property-list-img .property-save-btn ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.property-list-view .property-list-wrapper .property-list-img .property-save-btn ul li {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 0.57);
    border-radius: 50%;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    margin: 0 2px;
}

.property-list-view .property-list-wrapper .property-list-img .property-save-btn ul li a i {
    color: #ccc;
    font-size: 16px;
    line-height: 36px;
}

.property-list-view .property-list-wrapper .property-list-img:hover .property-save-btn {
    opacity: 1;
    visibility: visible;
    transition: 0.3s all;
}

.property-list-view .property-list-wrapper .property-list-img .ratings {
    position: absolute;
    bottom: 0;
    background: #fff;
    z-index: 100;
    padding: 2px 20px;
    padding-right: 5px;
}

.property-list-view .property-list-wrapper .property-list-img .ratings:after {
    position: absolute;
    content: '';
    display: block;
    left: 100%;
    top: 0;
    border-top: 32px solid transparent;
    border-left: 32px solid;
    border-left-color: #ffffff;
}

.property-list-view .property-list-wrapper .property-list-content {
    flex: 0 0 calc(100% - 280px);
    max-width: calc(100% - 280px);
    width: 100%;
    background-color: #fff;
}

.property-list-view .property-list-wrapper .property-list-content .content-box h4 {
    font-size: 22px;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .desc {
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-feature {
    display: flex;
    padding-bottom: 10px;
    overflow-y: auto;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-feature.active {
    cursor: grab;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-feature li {
    padding: 0px 30px 0px 0px;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-feature li:after {
    content: '';
    background: #eee;
    height: 100%;
    width: 2px;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 15px;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-feature::-webkit-scrollbar,
.property-list-view .property-list-wrapper .property-list-content .content-box .property-feature li:last-child:after {
    display: none;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-feature li i {
    font-size: 18px;
    margin-right: 10px;
    color: #000000;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-list-footer {
    padding-top: 0px;
    background: #fff;
    border-top: 0px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.property-list-view .property-list-wrapper .property-list-content .content-box .property-list-footer .price {
    font-size: 24px;
}

.property-list-view .property-list-wrapper.full-width-layout .property-list-img {
    flex: 0 0 50%;
    max-width: 50%;
}

.property-list-view .property-list-wrapper.full-width-layout .property-list-content {
    flex: 0 0 50%;
    max-width: 50%;
}

.property-list-view .property-list-wrapper.full-width-layout .property-list-content .content-box h4 {
    font-size: 26px;
}


/*======================
6. Listing Detail
========================*/

.listing-detail-heading {
    position: relative;
    position: relative;
    padding: 20px 0;
    padding-left: 25px;
    background-color: #fff;
}

.listing-detail-heading:after {
    content: "";
    height: calc(100% - 0px);
    width: 5px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    position: absolute;
    top: 0px;
    left: 0;
}

.listing-detail .image-sec-table {
    background-color: #fff;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-for .slide-item {
    position: relative;
    overflow: hidden;
    height: 450px;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-for .slide-item a:before {
    position: absolute;
    top: 50%;
    opacity: 0;
    color: #fff;
    font-size: 26px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f002";
    pointer-events: none;
    z-index: 9000;
    transition: 0.4s;
    transform: translate(0px, -50%);
    left: 0;
    right: 0;
    text-align: center;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-for .slide-item a:after {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    content: '';
    transition: 0.4s;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-for .slide-item:hover a:before,
.listing-detail .image-sec-table .images-wrapper .detail-page-slider-for .slide-item:hover a:after {
    opacity: 1;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav .slide-item {
    border: 2px solid #f4f4f4;
    margin: 0 10px;
    height: 90px;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav .slide-item.slick-current {
    border: 2px solid #ed673c;
    border-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%) 1 stretch;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button {
    font-size: 0;
    position: absolute;
    top: 0%;
    text-align: center;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s all;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button.slick-prev {
    left: 0;
    width: 100%;
    text-align: left;
    width: 30px;
    background: #ffffff;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button.slick-next {
    right: 0;
    width: 100%;
    text-align: right;
    width: 30px;
    background: #ffffff;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button.slick-next:before,
.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button.slick-prev:before {
    content: "\f165";
    font-family: Flaticon;
    font-size: 35px;
    z-index: 1000;
    line-height: 1;
    color: #ed673c;
    transition: 0.5s all;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button.slick-next:before {
    content: "\f163";
    font-family: Flaticon;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button:hover {
    background: #ed673c;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    transition: 0.5s all;
}

.listing-detail .image-sec-table .images-wrapper .detail-page-slider-nav button:hover:before {
    color: #fff;
    transition: 0.5s all;
}

.listing-detail .property-details-box .price-box {
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listing-detail .property-details-box .details {
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    padding: 20px 0;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.listing-detail .property-details-box .details .text,
.listing-detail .property-details-box .details-2 .text {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.listing-detail .property-details-box .details .text span {
    margin-left: 15px;
}

.listing-detail .description-box .head {
    border-bottom: 2px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.listing-detail .description-box .desc {
    margin-bottom: 20px;
}

.listing-detail .description-box .content-box .listing-infor {
    columns: 3;
    margin-bottom: 10px;
}

.listing-detail .description-box .content-box .listing-infor li {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
}

.listing-detail .description-box .content-box .listing-infor li:after {
    content: "\f058";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    left: 0;
    color: #ed673c;
    font-size: 14px;
}

.listing-detail .description-box .content-box .location #locmap {
    height: 350px;
}

.listing-detail .description-box .content-box .contact-form form textarea {
    height: 190px;
}

.listing-detail .description-box .content-box .contact-form .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/*======================
7. Listing Detail Classic
========================*/

.listing-slider-classic-sec .listing-slider-classic .swiper-wrapper .swiper-slide {
    height: 500px;
    width: auto;
}

.listing-slider-classic-sec .listing-slider-classic .swiper-wrapper .swiper-slide iframe {
    width: 800px;
    height: 100%;
}

.property-basic-info {
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px 0;
}

.property-basic-info .property-basic-info-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.property-basic-info .property-basic-info-wrapper .property-price {
    position: relative;
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 15%;
    padding: 5px 15px 0 0;
    font-size: 26px;
    font-weight: 600;
    color: #ed673c;
}

.property-basic-info .property-basic-info-wrapper .property-details {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 16%;
    border-left: 1px solid #e1e1e1;
    padding: 0 15px;
}

.property-basic-info .property-basic-info-wrapper .property-details .property-icon {
    width: 32px;
}

.property-basic-info .property-basic-info-wrapper .property-details .property-content {
    width: calc(100% - 42px);
    padding-left: 10px;
    font-size: 13px;
    line-height: 1.5em;
}

.property-basic-info .property-basic-info-wrapper .property-details .property-content .property-label {
    display: block;
    font-weight: 300;
}

.property-basic-info .property-basic-info-wrapper .property-cta {
    width: 21%;
}

.listing-header {
    border-bottom: 1px solid #ccc;
}

.listing-header .header-middle-sec,
.listing-header .header-top-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.listing-header .header-middle-sec .left-side ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.listing-header .header-middle-sec .left-side ul li {
    position: relative;
    padding-right: 30px;
    margin-bottom: 20px;
}

.listing-header .header-middle-sec .left-side ul li:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: #ccc;
    width: 1px;
    display: inline-block;
    margin-left: 15px;
}

.listing-meta .content-box {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.listing-meta .content-box .meta-content .listing-property-details>ul {
    columns: 2;
    margin: 0 -15px 10px;
}

.listing-meta .content-box .meta-content .listing-property-details>ul>li {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 10px;
    padding: 0 15px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.listing-meta .content-box .meta-content .listing-property-details>ul>li .left-side .icon img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}

.listing-meta .content-box .meta-content .listing-property-details>ul>li .left-side .icon i {
    font-size: 16px;
    margin-right: 8px;
}

.listing-meta .content-box .meta-content .listing-property-details iframe {
    height: 450px;
}

.listing-meta .content-box .meta-content .listing-property-details table th,
.listing-meta .content-box .meta-content .listing-property-details table td {
    white-space: nowrap;
    padding: 10px;
}

.listing-meta .content-box .meta-content .listing-property-details .custom-accordion .card .card-header ul {
    display: flex;
    position: absolute;
    right: 15px;
}

.listing-meta .content-box .meta-content .listing-property-details .custom-accordion .card .card-header .btn-link.collapsed ul li span {
    color: #111111;
}

.listing-meta .content-box .meta-content .listing-property-details .custom-accordion .card .card-header ul li {
    margin-left: 10px;
}

.listing-meta .content-box .meta-content .listing-property-details .floorplan-img {
    height: 310px;
    display: block;
    width: 310px;
    margin: 0 auto;
}

.listing-meta .content-box .meta-content .listing-property-details .tag-box {
    margin-bottom: 10px;
}

.listing-meta .content-box .meta-content .listing-property-details .tag-box .tags {
    margin-left: 15px;
}

.listing-meta .content-box .meta-content .listing-property-details .tag-box .tags a {
    display: inline-block;
    padding: 5px 15px;
    border: #ccc solid 1px;
    margin: 0 5px 10px 0;
    background: #ffffff;
    font-weight: 500;
    color: #111111;
}

.listing-meta .content-box .meta-content .listing-property-details .tag-box .tags a:hover {
    border-color: #ed673c;
    color: #ed673c;
}

.property-tag-2:hover {
    color: #ffffff;
}


/*======================
8. Profile
========================*/

.broker-contact {
    margin-bottom: 15px;
}

.broker-contact li {
    margin-bottom: 5px;
}

.broker-contact li a {
    margin-left: 10px;
    transition: 0.3s all;
}

.broker-contact li:hover a {
    padding-left: 15px;
    transition: 0.3s all;
}

.broker-social ul {
    display: flex;
    justify-content: flex-start;
}

.broker-social ul li a {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #ed673c;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #ed673c;
}

.broker-social ul li a:hover {
    background-color: #ed673c;
    color: #fff;
}


/*======================
9. About Us
========================*/

.satisfy-clients {
    background-image: url(../images/about-us/banner-1920x450.jpg);
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.satisfy-clients:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
}

.satisfy-clients .counter-box {
    display: flex;
    align-items: center;
    max-width: 100%
}

.satisfy-clients .counter-box .counter-icon {
    margin-right: 20px;
    font-size: 65px;
    line-height: 1;
    color: #fff;
}

.satisfy-clients .counter-box .counter {
    text-transform: capitalize;
    white-space: nowrap;
}

.satisfy-clients .counter-box .counter span.count {
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    display: block;
}


/*======================
10. Faqs
========================*/

.faq-box .faq-wrapper .title-img {
    width: 100%;
    height: 145px;
}


/*======================
11. 404
========================*/


/*404 page*/

#page-404,
.coming-soon {
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
}

#page-404 {
    background-image: url(../images/404.jpg);
}

.coming-soon {
    background-image: url(../images/coming-soonbg.jpg);
}

.coming-soon:before,
#page-404:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    left: 0;
    background-image: linear-gradient(to right, #00000073, #ffffff80);
}

#page-404 section {
    display: flex;
    justify-content: center;
    height: 100vh;
    text-align: center;
    align-items: center;
    width: 100%;
}

#page-404 .caption h5 {
    margin: 50px 0;
    font-weight: 400;
}

.coming-soon .content-wrapper h1 a,
#page-404 .caption h5 a {
    text-decoration: underline;
    text-underline-position: under;
}


/*======================
12. Coming Soon
========================*/


/*coming soon*/

.coming-soon {
    padding: 30px 0;
    min-height: 100vh;
}

.coming-soon .content-wrapper {
    padding: 100px 0px;
}

.coming-soon .content-wrapper .section-header {
    margin: 0;
    text-align: left;
}

.coming-soon .content-wrapper .section-header h1 {
    word-break: break-word;
    font-size: 50px;
}

.coming-soon .content-wrapper .counter {
    display: flex;
    margin: 30px 0;
}

.coming-soon .content-wrapper .counter .counter-box {
    margin-right: 20px;
    text-align: center;
}

.coming-soon .content-wrapper .counter .counter-box .inner-box {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 0px;
    margin-bottom: 10px;
}

.coming-soon .content-wrapper .newsletter-form {
    max-width: 550px;
}

.coming-soon .content-wrapper .newsletter-form input::placeholder,
.coming-soon .content-wrapper .newsletter-form input {
    background: transparent;
    color: #fff;
}

.coming-soon .content-wrapper .newsletter-form .input-group-append {
    margin-left: 10px;
}

.coming-soon .content-wrapper .social-media-icons ul {
    display: flex;
    padding: 0;
    list-style: none;
}

.coming-soon .content-wrapper .social-media-icons ul li a {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    margin-right: 5px;
    color: #fff;
    border-radius: 0px;
}

.coming-soon .content-wrapper .social-media-icons ul li:hover a {
    background: #ed673c;
}


/*======================
13. Contact Us
========================*/

.contact-us {
    background-image: url(../images/map-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.contact-us .contact-us-form {
    background-color: #ed673c;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    padding: 65px 50px;
    margin-bottom: -50px;
    margin-left: -30px;
}

.contact-us .contact-us-form .form-control::placeholder {
    color: #fff;
}

.contact-us .contact-us-form h4 {
    font-size: 35px;
    font-weight: 700;
}

.contact-us .contact-info {
    background-color: #fff;
    padding: 40px 50px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.contact-us .contact-info .seperator {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.contact-us .contact-info h5 {
    margin-bottom: 10px;
}

.contact-map,
.contact-map iframe {
    width: 100%;
    height: 380px;
    border: none;
}


/*======================
14. Gallery
========================*/

.gallery .grid .grid-item {
    position: relative;
    overflow: hidden;
}

.gallery .grid .grid-item a:before {
    position: absolute;
    top: 50%;
    opacity: 0;
    color: #fff;
    font-size: 26px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f002";
    pointer-events: none;
    z-index: 9000;
    transition: 0.4s;
    transform: translate(0px, -50%);
    left: 0;
    right: 0;
    text-align: center;
}

.gallery .grid .grid-item a:after {
    position: absolute;
    top: 0;
    left: 15px;
    width: 92.3%;
    height: 100%;
    opacity: 0;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
    content: '';
    transition: 0.4s;
}

.gallery .grid .grid-item:hover a:before,
.gallery .grid .grid-item:hover a:after {
    opacity: 1;
}


/*======================
15. Service Detail
========================*/

.service-detail-infro {
    margin-bottom: 30px;
}

.service-detail-infro {
    columns: 2;
}

.service-detail-infro li {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
}

.service-detail-infro li:after {
    content: "\f162";
    font-family: "Flaticon";
    font-weight: 900;
    position: absolute;
    left: 0;
    color: #ed673c;
    font-size: 14px;
}

.service-detail-slide .slide-item {
    height: 450px;
}

.work-process-sec .work-process-text {
    padding: 80px 15px;
    margin-right: 8%;
}

.work-process-sec .work-process-text .feature-box {
    position: relative;
    padding: 0 0 0 100px;
}

.work-process-sec .work-process-text .feature-box .fbox-icon {
    display: block;
    position: absolute;
    width: 80px;
    height: auto;
    top: 0;
    left: 0;
}

.work-process-sec .work-process-text .feature-box .fbox-icon a,
.work-process-sec .work-process-text .feature-box .fbox-icon i {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
}

.work-process-sec .work-process-text .feature-box h5 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.advertisement-banner {
    display: block;
    padding: 80px 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    background-image: linear-gradient(147deg, #ea7742 0%, #FF2525 74%);
}

.advertisement-banner strong {
    border-bottom: 2px solid #fff;
}

.advertisement-banner i {
    position: relative;
    top: 3px;
    left: 5px;
}

.contact-us-form .form-control-custom {
    border: 2px solid #ffffff;
}

.full-services-sec .right-side .text-wrapper{
    padding: 80px 0;
}

.blog-details .author-caption .social-media-box>ul{
  margin-bottom: 0;
}

.comment-respond input.form-control-custom::placeholder,
.comment-respond textarea.form-control-custom::placeholder{
  color: #000000;
  background: transparent;
}
